import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppModules, CompanyStatusEnum, CompanyTypeEnum, IAccount, ICompanyRole, RolesEnum } from '@smarttypes/core';
import { ConfirmModalComponent } from '@ui/common/modals';
import { hasAtLeastOneModule, hasModule, hasModules } from 'angular-v2-utils';
import { get, set } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, forkJoin, interval, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IMenuItem } from '../../components/layout/sidebar/menu-group/menu-group.component';
import { AccountService } from '../../core/services/account.service';
import { AppVersionService } from '../../core/services/app-version.service';
import { ApplicationLanguageService } from '../../core/services/application-language.service';
import { CompanyService } from '../../core/services/company.service';
import { CountersService } from '../../core/services/counters.service';
import { IntercomService } from '../../core/services/intercom.service';
import { NavigationService } from '../../core/services/navigation.service';
import { NotificationBarService, NotificationIdEnum } from '../../core/services/notification-bar.service';
import { PaymentRequiredService } from '../../core/services/payment-required.service';
import { VisitorsApartmentEditorService } from '../../modules/visitors-configurator/visitors-apartment/visitors-apartment-editor.service';
import { LocalizeService } from '../../services/localize.service';
import { RoomsService } from '../../services/rooms.service';

@Component({
  selector: 'sh-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardViewComponent implements OnInit, OnDestroy {
  menuStandaloneOperations: IMenuItem[] = [];
  menuStandaloneResources: IMenuItem[] = [];
  menuStandaloneTop: IMenuItem[] = [];
  customCssClass = '';
  showNotificationBar = false;
  accountExpired = false;
  @HostBinding('class.showTopBar') showTopBar = environment?.ui?.top?.enabled ?? true;
  @HostBinding('class.showSideBar') showSideBar = environment?.ui?.sidebar?.enabled ?? true;
  @HostBinding('class.sidebar-closed') sidebarClosed = true;
  @ViewChild('notificationBar', { read: ElementRef }) notificationBarEl?: ElementRef;
  @ViewChild('topBar', { read: ElementRef }) topBarEl?: ElementRef;
  @ViewChild('topNavigation', { read: ElementRef }) topNavigationEl?: ElementRef;
  private companyStatus: CompanyStatusEnum | undefined;
  private tokenVerificationSub!: Subscription;
  private company!: ICompanyRole;

  constructor(
    private readonly companyService: CompanyService,
    private readonly roomService: RoomsService,
    private readonly accountService: AccountService,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: BsModalService,
    private readonly router: Router,
    private readonly intercomService: IntercomService,
    private counterService: CountersService,
    private httpClient: HttpClient,
    private apartmentEditorService: VisitorsApartmentEditorService,
    private readonly navigationService: NavigationService,
    private readonly cdr: ChangeDetectorRef,
    private notificationBarService: NotificationBarService,
    private paymentRequiredService: PaymentRequiredService,
    private readonly localizeService: LocalizeService,
    private appVersionService: AppVersionService,
    private appLangService: ApplicationLanguageService,
  ) {
    this.listenOnRouteChange();
  }

  @HostBinding('class.showTopNavigation')
  get showTopNavigation(): boolean {
    return this.navigationService.isTopNavigationVisible;
  }

  @HostBinding('class') get class() {
    return this.customCssClass;
  }

  @HostBinding('style') get style() {
    const topBar = this.topBarEl?.nativeElement?.clientHeight ?? 0;
    const topNavigation = this.topNavigationEl?.nativeElement?.clientHeight ?? 0;
    const totalContentOffset = this.topOffset + topBar + topNavigation;
    return {
      'padding-top': `${totalContentOffset}px`,
    };
  }

  get topOffset(): number {
    return this.notificationBarEl?.nativeElement?.clientHeight ?? 0;
  }

  get getAccountExpirationStatus(): boolean {
    return (
      !!this.companyStatus &&
      [CompanyStatusEnum.trialEnded, CompanyStatusEnum.paused, CompanyStatusEnum.churn].includes(this.companyStatus) &&
      !this.allowExpiredAccounts
    );
  }

  get isAccountPreliminaryTrial(): boolean {
    return this.companyStatus === CompanyStatusEnum.preliminaryTrial;
  }

  get gaConfiguredModal(): boolean {
    return get(environment, 'ui.guestAreaConfiguredWelcomeModal', false);
  }

  get allowExpiredAccounts(): boolean {
    return get(environment, 'allowExpiredAccounts', false);
  }

  get hidePayments(): boolean {
    return get(environment, 'ui.hidePayments', false);
  }

  get hideGaV1(): boolean {
    return get(environment, 'ui.hideGuestAreaV1', false);
  }

  ngOnInit(): void {
    this.intercomService.bootIntercom();
    this.counterService.initCounters();
    this.initNotificationBar();

    this.tokenVerificationSub = interval(10000).subscribe(() => {
      this.companyService.verifyCompanyTokens();
    });

    forkJoin([
      this.accountService.$currentUser().pipe(first()),
      this.companyService.$currentUser().pipe(first()),
    ]).subscribe(([account, company]) => {
      // @TODO: create services for navigation, i18n
      this.companyStatus = company?.status;
      this.company = company;

      this.localizeService.init();

      this.initNavigation(company);
      this.setLanguageBasedOnAccount(account);

      if (this.gaConfiguredModal) {
        this.initGaConfiguredModal();
      }

      if (!environment.i18n?.type && company.type !== CompanyTypeEnum.hotel) {
        this.loadNonHotelTranslations(company);
      }

      if (this.getAccountExpirationStatus) {
        this.handleAccountExpiration();
      }
    });
  }

  ngOnDestroy() {
    if (this.tokenVerificationSub) {
      this.tokenVerificationSub.unsubscribe();
    }
  }

  toggleSidebar(visibility: boolean) {
    this.sidebarClosed = visibility;
  }

  private handleAccountExpiration() {
    this.accountExpired = true;
    this.notificationBarService.addNotification({
      id: NotificationIdEnum.AccountExpired,
      header: this.translateService.instant('SH.ACCOUNT.EXPIRED.TITLE'),
      description: this.translateService.instant('SH.ACCOUNT.EXPIRED.DESCRIPTION'),
      icon: 'icons-warning-filled',
      type: 'warning',
      html: true,
    });

    this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-default',
      ignoreBackdropClick: true,
      initialState: {
        text: {
          header: this.translateService.instant('SH.FRONTEND_LIB.ACCOUNT_EXPIRED_MODAL.TITLE'),
          body: this.translateService.instant('SH.FRONTEND_LIB.ACCOUNT_EXPIRED_MODAL.DESCRIPTION'),
          submit: this.translateService.instant('SH.FRONTEND_LIB.ACCOUNT_EXPIRED_MODAL.CONTACT_SALES'),
          blockButtons: true,
          cancel: '',
        },
        confirmFunc: () => {
          const subject = this.translateService.instant('I would like to renew my subscription.');
          window.open(`mailto:sales@mysmarthotel.com?subject=${subject}`);
        },
      },
    });
  }

  private initGaConfiguredModal() {
    if (get(this.company, 'config.hotres.welcomeModalShown', false)) {
      return;
    }

    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        canClose: false,
        text: {
          icon: 'icons-checkmark-filled',
          large: true,
          header: 'SH.GA_WELCOME_MODAL.HEADER',
          body: 'SH.GA_WELCOME_MODAL.DESCRIPTION',
          submit: 'SH.GA_WELCOME_MODAL.BUTTON',
          btnClass: 'primary',
          cancel: '',
        },
        warning: {
          icon: 'icons-warning-filled',
          text: 'SH.GA_WELCOME_MODAL.AUTOMATION_INFO',
        },
        confirmFunc: () => {
          if (!this.company) {
            return;
          }
          set(this.company, 'config.hotres.welcomeModalShown', true);
          this.companyService.update(this.company).subscribe();
        },
      },
      class: 'modal-dialog-medium',
    });
  }

  private listenOnRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => route.firstChild),
        switchMap(route => route?.data as any),
      )
      .subscribe((data: any) => {
        if (data.customCssClass === undefined) {
          this.customCssClass = '';

          return;
        }

        this.customCssClass = data.customCssClass;
      });
  }

  private checkMissingRequiredApartmentInformation(company: ICompanyRole): Observable<boolean> {
    if (hasModule(company?.modulesV2, AppModules.apartmentsEditor)) {
      return this.apartmentEditorService.status().pipe(map(r => r.emptyRequiredFields));
    } else {
      return of(false);
    }
  }

  private loadNonHotelTranslations(company: ICompanyRole) {
    // TODO: move it to i18n.service.ts
    const lang = this.appLangService.getCurrentLanguage();
    const url = environment?.i18n?.url ? environment.i18n.url : `${environment.apiUrl}translate/app/sh/`;
    const force = environment?.i18n?.force ? `&force=true` : '';

    this.httpClient.get(`${url}${lang}?type=${company.type}${force}`).subscribe(translations => {
      this.translateService.setTranslation(lang, translations);
      this.appLangService.setLanguage(lang);
      this.cdr.detectChanges();
    });
  }

  private initNotificationBar() {
    this.notificationBarService.$notifications
      .pipe(
        debounceTime(500),
        map(notifications => notifications.length > 0),
        tap(show => (this.showNotificationBar = show)),
      )
      .subscribe(show => {
        this.showNotificationBar = show;
      });
  }

  private initNavigation(company: ICompanyRole) {
    const menuStandaloneOperations: IMenuItem[] = [];
    const canShowUpgradeArrow =
      (company.role === RolesEnum.owner ||
        company.role === RolesEnum.admin ||
        company.role === RolesEnum.service ||
        company.role === RolesEnum.manager) &&
      environment.ui?.canShowUpgradeArrow;

    // TODO: move menu logic to navigation.service.ts
    if (hasAtLeastOneModule(company?.modulesV2, [AppModules.unifiedInbox, AppModules.smartAssistant])) {
      menuStandaloneOperations.push({
        path: '/livechat',
        label: 'SH.CHAT.TITLE',
        icon: 'icons-chat',
        counter: this.counterService.livechat,
        totalCounter: of(0),
      });
    } else {
      if (!environment.hideUnavailableModulesInMenu && canShowUpgradeArrow) {
        menuStandaloneOperations.push({
          path: '',
          label: 'SH.CHAT.TITLE',
          icon: 'icons-chat',
          unlockRequired: true,
        });
      }
    }
    if (hasModule(company?.modulesV2, AppModules.smartAssistantDemo)) {
      menuStandaloneOperations.push({
        path: '/smart-ai',
        label: 'SH.SMART_AI.TITLE',
        icon: 'icons-chat-gpt',
        beta: true,
      });
    }
    if (hasModule(company?.modulesV2, AppModules.guests)) {
      menuStandaloneOperations.push({
        path: '/guests',
        label: 'SH.VISITORS_LIST.TITLE',
        icon: 'icons-user',
        counter: this.counterService.guests,
      });
    } else {
      if (!environment.hideUnavailableModulesInMenu && canShowUpgradeArrow) {
        menuStandaloneOperations.push({
          path: '',
          label: 'SH.VISITORS_LIST.TITLE',
          icon: 'icons-user',
          unlockRequired: true,
        });
      }
    }

    if (hasModule(company?.modulesV2, AppModules.tasks)) {
      menuStandaloneOperations.push({
        path: '/tasks',
        label: 'SH.TASKS.TITLE',
        icon: 'icons-to-do',
      });
    }

    if (!environment.hideUnavailableModulesInMenu || hasModule(company?.modulesV2, AppModules.massMessaging)) {
      menuStandaloneOperations.push({
        path: '/mass-messaging',
        label: 'SH.MASS_MESS.TITLE',
        icon: 'icons-message',
        unlockRequired: !hasModule(company?.modulesV2, AppModules.massMessaging),
      });
    }

    if (!this.hidePayments) {
      menuStandaloneOperations.push({
        path: '/payments',
        label: 'SH.PAYMENTS_VIEW.TITLE',
        icon: 'icons-payment',
      });
    }

    if (
      hasModule(company?.modulesV2, AppModules.requests) ||
      environment?.showUnlocked ||
      !environment.hideUnavailableModulesInMenu
    ) {
      menuStandaloneOperations.push({
        path: '/requests',
        label: 'SH.REQUESTS.TITLE',
        icon: 'icons-repository',
        unlockRequired: !hasModule(company?.modulesV2, AppModules.requests),
      });
    }

    if (hasModule(company?.modulesV2, AppModules.qrMaterials)) {
      menuStandaloneOperations.push({
        path: '/qr-materials',
        label: 'SH.MATERIALS_VIEW.TITLE',
        icon: 'icons-qr-small',
        allowForTrialUsers: this.isAccountPreliminaryTrial,
      });
    }

    this.menuStandaloneOperations = menuStandaloneOperations;

    const menuStandaloneResources: IMenuItem[] = [];
    if (hasModule(company?.modulesV2, AppModules.visitorsArea) && !this.hideGaV1) {
      menuStandaloneResources.push({
        path: '/guest-area',
        label: 'SH.GUEST_AREA_VIEW.TITLE',
        icon: 'icons-instruction-large',
        warning: this.checkMissingRequiredApartmentInformation(company),
      });
    }
    if (hasModule(company?.modulesV2, AppModules.visitorsPlaceModule)) {
      menuStandaloneResources.push({
        path: '/visitors-place',
        label: 'SH.VISITORS_PLACE.TITLE',
        icon: 'icons-instruction-large',
      });
    }
    if (hasModules(company?.modulesV2, [AppModules.automationSettings, AppModules.smartAutomation])) {
      menuStandaloneResources.push({
        path: '/automation-settings',
        label: 'SH.AUTOMATION_SETTINGS.TITLE',
        icon: 'icons-automation',
      });
    }
    if (hasModule(company?.modulesV2, AppModules.registrationForm)) {
      menuStandaloneResources.push({
        path: '/registration-form',
        label: 'SH.CHECK-IN.TITLE',
        icon: 'icons-sign',
      });
    }
    if (hasModule(company?.modulesV2, AppModules.mediaLibrary)) {
      menuStandaloneResources.push({
        path: '/media-library',
        label: 'SH.LIBRARY_VIEW.LIST.TITLE',
        icon: 'icons-library',
      });
    }
    if (hasModule(company?.modulesV2, AppModules.users)) {
      menuStandaloneResources.push({
        path: '/users',
        label: 'SH.USERS_VIEW.LIST.TITLE',
        icon: 'icons-users',
        counter: of(0),
      });
    }
    if (hasAtLeastOneModule(company?.modulesV2, [AppModules.rooms, AppModules.apartments])) {
      menuStandaloneResources.push({
        path: '/rooms',
        label: 'SH.ROOMS_VIEW.LIST.TITLE',
        icon: 'icons-room',
        totalCounter: this.roomService.$counter,
      });
    } else if (!hasModule(company?.modulesV2, AppModules.apartments) && canShowUpgradeArrow) {
      if (environment?.showUnlocked && !environment.hideUnavailableModulesInMenu) {
        menuStandaloneResources.push({
          path: '',
          label: 'SH.ROOMS_VIEW.LIST.TITLE',
          icon: 'icons-room',
          unlockRequired: true,
        });
      }
    }
    if (hasModule(company?.modulesV2, AppModules.buildings)) {
      menuStandaloneResources.push({
        path: '/buildings',
        label: 'SH.BUILDINGS_VIEW.LIST.TITLE',
        icon: 'icons-room',
      });
    }
    if (hasModule(company?.modulesV2, AppModules.analytics)) {
      menuStandaloneResources.push({
        path: '/analytics',
        label: 'SH.ANALYTICS_VIEW.TITLE',
        icon: 'icons-analytics',
        allowForTrialUsers: this.isAccountPreliminaryTrial,
      });
    }
    if (this.allowExpiredAccounts) {
      for (const item of menuStandaloneOperations.concat(menuStandaloneResources)) {
        item.disabled = this.getAccountExpirationStatus;
      }
    }

    if (hasModule(company?.modulesV2, AppModules.integrations)) {
      menuStandaloneResources.push({
        path: '/integrations',
        label: 'SH.INTEGRATIONS_VIEW.TITLE',
        icon: 'icons-integrations',
      });
    }

    this.menuStandaloneResources = menuStandaloneResources;

    this.menuStandaloneTop = [
      {
        path: '/livechat',
        label: 'SH.CHAT.TITLE',
        icon: '',
        module: AppModules.unifiedInbox,
      },
      {
        path: '/automation-settings',
        label: 'SH.AUTOMATION_SETTINGS.TITLE',
        icon: '',
        module: AppModules.smartAutomation,
      },
      {
        path: '/guests',
        label: 'SH.VISITORS_LIST.TITLE',
        icon: '',
        module: AppModules.guests,
      },
      {
        path: '/guest-area',
        label: 'SH.GUEST_AREA_VIEW.TITLE',
        icon: '',
        module: AppModules.visitorsArea,
      },
    ].filter(item => (environment?.ui?.topNavigation?.modules ?? []).includes(item.module));
  }

  private setLanguageBasedOnAccount(account: IAccount) {
    if (account?.language && !environment?.ui?.user?.login?.forceLanguage) {
      this.appLangService.setLanguage(account?.language);
      this.cdr.detectChanges();
    }
  }
}
