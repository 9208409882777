import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from 'angular-v2-utils';
import { filter, map } from 'rxjs/operators';

import { RadioButtonValue } from '../radio-buttons/radio-buttons.component';

@Component({
  selector: 'sh-checkbox-buttons',
  templateUrl: './checkbox-buttons.component.html',
  styleUrls: ['./checkbox-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxButtonsComponent),
      multi: true,
    },
  ],
})
export class CheckboxButtonsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() values: RadioButtonValue[] = [];
  @Input() name!: string;
  public value = null;
  public form: FormGroup = new FormGroup({});
  public tagsExpanded = false;
  private isRegister = false;
  private onTouch!: () => void;
  private onModelChange!: (value: any) => void;

  get collapseItems(): boolean {
    return this.values.length > 4;
  }

  constructor() {}

  ngOnInit() {
    this.values.forEach(item => {
      this.form.addControl(item.value, new FormControl(true));
    });

    this.form.valueChanges
      .pipe(
        filter(p => !!p),
        untilDestroyed(this),
        map(form => {
          return Object.keys(form)
            .reduce((aggr: string[], key) => {
              if (form[key]) {
                aggr.push(key);
              }
              return aggr;
            }, [])
            .sort();
        }),
      )
      .subscribe(value => {
        console.log(value);
        this.valueChange(value);
      });
  }

  toggle() {
    this.tagsExpanded = !this.tagsExpanded;
    this.values.sort((a, b) => (a.label > b.label ? 1 : -1));
    if (!this.tagsExpanded) {
      this.values.sort((a, b) => {
        if ((this.value as any).includes(a.value) > (this.value as any)?.includes(b.value)) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  }

  ngOnDestroy() {}

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
    this.isRegister = true;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(): void {}

  writeValue(obj: any): void {
    this.value = obj;
    if (typeof this.value === 'string') {
      this.form.patchValue(
        {
          orders: (this.value as string)?.split(','),
        },
        { emitEvent: false },
      );
    } else if (!this.value) {
      this.form.reset({}, { emitEvent: false });
    }
  }

  valueChange($event: any) {
    this.value = $event;
    if (this.isRegister) {
      this.onModelChange(this.value);
      this.onTouch();
    }
  }
}
